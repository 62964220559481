body, html {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App-header {
  background-color: #B36B5C;
  padding: 20px;
  color: white;
  text-align: center;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.about-section {
  margin-bottom: 20px;
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.content {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.scrollable-list {
  overflow-y: auto;
  flex: 1;
  margin-right: 20px;
}

h1 {
  margin: 0;
}

h2 {
  color: #543B2F;
}

p {
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
  color: #543B2F;
}

strong {
  color: #B36B5C;
}

.image-container {
  width: 500px;
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.image-container .slick-slider {
  width: 300px;
}

.image-container .slick-slide {
  display: flex;
  justify-content: center;
}

.image-container img {
  width: 300px;
  max-height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.App-footer {
  text-align: center;
  padding: 20px;
  background-color: #B36B5C;
  color: white;
  width: 100%;
  flex-shrink: 0;
}

.App-footer a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.App-footer a:hover {
  text-decoration: underline;
}
